@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Itim&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:wght@200;300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Zeyada&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nanum+Pen+Script&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Gloria+Hallelujah&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lacquer&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lancelot&display=swap");

@font-face {
  font-family: "gilroy";
  src: url(./gilroy/Gilroy-Bold.ttf);
}

@font-face {
  font-family: "gilroy";
  src: url(./gilroy/Gilroy-Regular.ttf);
}

*:root {
  --check_font_family: "gilroy";
  --font_family: "gilroy";
  --header_bgColor: rgb(243 250 252);
  /*Top Header BGColor */
  --icon_color: rgb(0 183 235);
  /*Dashboard Widget icon Color */
  --bg_active_color: rgb(0 183 235 / 15%);
  /* Active Background Color */
  --header_logo_txt_color: rgb(0 0 0);
  /*Top Header Logo Text Color */
  --bg_opacity: rgb(0 0 0 / 28%);
  /* Background Opacity */
  --border_color: rgb(230, 230, 230);
  /*Border Color */
  --form_label_color: rgb(0 0 0 / 80%);
  /* Form Label Color*/
  --viewport_bg_color: rgb(255 255 255);
  /*Viewport BG-Color */
  --bg_white: rgb(255 255 255);
  /* Widget background Color */
  --inactive_menu_color: rgb(67, 67, 67);
  /* Inactive Menu Color */
  --transparent_color: transparent;
  /* For transparent Color */
  --notification_dot: rgb(220 53 69);
  /* Notification Dot Color */
  --alert_background: rgb(220 53 69 / 15%);
  /* Alert Background Color */
  --active_color: rgb(0, 128, 0);
  /* Active Color */
  --inactive_color: rgb(255, 0, 0);
  /* Inactive Color */
  --pending_color: rgb(128, 128, 128);
  /* Pending Color */
  --check_active_color: rgb(0, 128, 0);
  /* Active Color */
  --check_inactive_color: rgb(255, 0, 0);
  /* Inactive Color */
  --check_pending_color: rgb(128, 128, 128);
  /* Pending Color */
  --red_color: rgb(255, 0, 0);
  /* Red Color */
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
  font-family: var(--font_family);
  font-size: 12px;
  font-size: 100%;
  box-sizing: inherit;
  scroll-behavior: smooth;
  height: -webkit-fill-available;
  font-weight: 400;
  line-height: 1.5;
  user-select: auto !important;
}

.checkFontClass {
  font-family: var(--check_font_family);
}

.checkActiveColor {
  color: var(--check_active_color);
}

.checkInactiveColor {
  color: var(--check_inactive_color);
}

.checkPendingColor {
  color: var(--check_pending_color);
}

.swal2-confirm {
  background-color: var(--icon_color) !important;
}

.form-switch .form-check-input {
  width: 3em;
}

.form-check-input:focus {
  box-shadow: none;
  border-color: #dee2e6;
}

.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
}

.form-switch input {
  padding: 8px;
}

.form-check-input:checked {
  background-color: var(--icon_color);
  border-color: var(--bg_white);
}

p {
  font-size: 13px;
}

:disabled {
  cursor: no-drop;
}

.ulheight {
  height: 145px;
  overflow: auto;
}

div:where(.swal2-icon).swal2-success {
  border-color: var(--icon_color) !important;
  color: var(--icon_color) !important;
}

div:where(.swal2-icon).swal2-success .swal2-success-ring {
  border: 0.25em solid var(--icon_color) !important;
}

div:where(.swal2-icon).swal2-success [class^="swal2-success-line"] {
  background-color: var(--icon_color) !important;
}

div:where(.swal2-container) div:where(.swal2-actions) {
  white-space: nowrap;
  flex-wrap: inherit !important;
}

.swal2-confirm:focus,
.swal2-default-outline:focus {
  box-shadow: none !important;
}

.form-control:focus,
.form-select:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
}

.btn-close:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0);
}

.flex-wrapper {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex: 1;
}

.sidebar {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 250px;
  color: var(--header_logo_txt_color);
  -webkit-transition: ease width 2s;
  transition: ease all 1s;
  border-right: 1px solid var(--border_color);
  animation-name: openSidebarSection;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
}

.sidebar.collapsed {
  width: 50px;
}

.sidebar-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px; */
}

.admin-main {
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
}

.admin-main-header {
  display: flex;
  border-bottom: 1px solid var(--border_color);
  align-items: center;
  padding: 0 1em;
  height: 51px;
  background: var(--header_bgColor);
  position: relative;
}

.admin-main-content-area {
  padding: 1em;
  background-color: var(--viewport_bg_color);
  height: calc(100vh - 51px);
  overflow: auto;
}

.deskToplogo {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 12.5px 10px;
  border-bottom: 1px solid var(--border_color);
  letter-spacing: 0.5px;
  font-size: 18px;
  background: var(--header_bgColor);
  overflow: hidden;
}

.mobileLogo {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 16px 10px;
  border-bottom: 1px solid var(--border_color);
  letter-spacing: 0.5px;
  font-size: 18px;
}

.sidebar .op-name {
  opacity: 1;
  text-wrap: nowrap;
}

.Sidebar_submenu_padding_btn {
  padding: 10px 0;
}

.sidebar.collapsed .op-name {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
}

.side-menu {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  width: 100%;
  margin: 0;
  line-height: 0;
}

.submenu.billing,
.submenu.aiAlphaControl,
.submenu.editor,
.submenu.setting {
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  overflow: hidden;
  z-index: 9;
}

.submenu.billing {
  animation-name: openDropdownSubadmin;
}

.submenu.aiAlphaControl {
  animation-name: openDropdownaiAlphaControl;
}

.submenu.editor {
  animation-name: openDropdownEditor;
}

.submenu.setting {
  animation-name: openDropdownAccount;
}

ul.submenu {
  padding-left: 18px;
  margin-top: -5px;
}

.submenu li {
  padding-left: 21px !important;
}

.sidebar.collapsed .side-menu i {
  font-size: 14px;
  margin: 0;
}

.side-menu i {
  font-size: 14px;
  margin-right: 0.7em;
}

.side-menu li {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 10px;
  transition: 1s;
  border-left: 4px solid transparent;
  padding-left: 15px;
}

.side-menu li:hover {
  background-color: var(--bg_active_color);
  color: var(--header_logo_txt_color);
  border-left: 4px solid var(--icon_color) !important;
  border-radius: 6px 0 0 6px;
}

.sidebar__active {
  background-color: var(--bg_active_color);
  color: var(--header_logo_txt_color);
  border-left: 4px solid var(--icon_color) !important;
  border-radius: 6px 0 0 6px;
}

.side-menu a {
  color: var(--header_logo_txt_color);
  text-decoration: none;
  font-size: 15px;
}

.side-menu a.active li {
  background-color: var(--bg_active_color);
  color: var(--header_logo_txt_color);
  border-left: 4px solid var(--icon_color) !important;
  border-radius: 6px 0 0 6px;
}

.side-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  line-height: 1em;
  min-width: 100px;
  margin: 12px 0;
  font-size: 14px;
  font-weight: bold;
  gap: 0;
  color: var(--inactive_menu_color);
  letter-spacing: 0.5px;
  cursor: pointer;
}

.sidebar__active .side-menu-item {
  color: var(--header_logo_txt_color);
  transition: 1s;
}

li:hover .side-menu-item {
  color: var(--header_logo_txt_color);
  transition: 1s;
}

#menu-btn {
  cursor: pointer;
  border-radius: 50%;
  background-color: var(--bg_active_color);
  font-size: 15px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#menu-btn:hover {
  background-color: var(--icon_color);
  color: var(--bg_white);
  transition: 1s;
}

.submenu .side-menu-item {
  font-size: 13px;
}

.sidebar .tooltip {
  display: none;
}

.sidebar.collapsed .tooltip {
  transition: opacity 0.3s ease-in-out;
  display: inline;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  left: 5em;
  margin: 0;
  margin-top: 0.6em;
}

.sidebar.collapsed .menu-item-area:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.sidebar.collapsed .tooltip .tooltipContent {
  overflow: hidden;
  position: relative;
  color: var(--bg_white);
  background-color: var(--icon_color);
  padding: 5px 15px;
  border-radius: 25px;
}

.sidebar.collapsed .tooltip .tooltipContent.logout {
  background-color: var(--notification_dot);
}

.relative {
  position: relative;
}

.scrollbox {
  overflow-x: hidden;
  background-color: var(--header_bgColor);
  height: calc(100vh - 51px);
  padding-top: 12px;
}

.paginate::-webkit-scrollbar {
  background-color: var(--transparent_color);
  height: 0px !important;
}

::-webkit-scrollbar {
  background-color: var(--transparent_color);
  width: 5px;
  height: 5px;
}

:hover::-webkit-scrollbar {
  height: 5px;
  width: 5px;
}

:hover::-webkit-scrollbar-thumb {
  background-color: var(--icon_color);
}

.product__tableBar {
  border: 1px solid var(--border_color);
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

/* Table Css ---------------------------- */

.table__for__store .caption {
  font-size: 1.5em;
  margin: 0 0 0.75em;
}

table thead tr th {
  background-color: var(--icon_color) !important;
  color: var(--bg_white) !important;
  padding: 0.35em;
}

.custom-table.table th,
.custom-table.table td,
.TablePre th,
.TablePre td,
.tableFont th,
.tableFont td {
  font-size: 13px;
}

td {
  padding: 5px 10px !important;
}

table th {
  letter-spacing: 0.1em;
}

table {
  margin-bottom: 0 !important;
}

.tableLibrary.custom-table th:last-child {
  width: 100% !important;
  table-layout: fixed;
}

.tableLibrary.custom-table {
  width: auto;
  max-width: max-content;
}

.custom-table {
  width: 100%;
  table-layout: fixed;
}

.custom-table.resizing {
  width: 100%;
  max-width: fit-content;
}

.custom-table th {
  resize: horizontal;
}

.custom-table th,
.custom-table td {
  border: 1px solid var(--border_color);
  text-align: left;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

tbody tr:nth-child(odd) td {
  background-color: var(--header_bgColor);
}

.tableLibrarySection {
  max-height: calc(100vh - 175px);
}

.headerFixed {
  position: sticky;
  top: 0;
}

/* ------------------------- Dashboard Widget CSS -------------------------*/

input,
textarea,
select {
  outline: none;
  resize: none;
  border: none;
  padding: 11px;
}

input,
button {
  outline: none !important;
  border: 0;
  width: 100%;
  padding: 10px;
}

button::-moz-focus-inner {
  border: 0;
}

/* Common Class */

.mb-20 {
  margin-bottom: 20px;
}

.font-24 {
  font-size: 24px;
  line-height: 1.5em;
}

.font-20 {
  font-size: 20px;
  line-height: 1.5em;
}

.font-18 {
  font-size: 18px;
  line-height: 1.6em;
}

.weight-500 {
  font-weight: 500;
  white-space: nowrap;
}

.weight-800 {
  font-weight: 800;
}

.text-theme {
  color: var(--icon_color);
}

.height-100-p {
  height: 100%;
}

.bg-white {
  background: var(--bg_white);
}

.border-radius-100 {
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
}

.box-shadow-max {
  -webkit-box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 28px rgba(0, 0, 0, 0.08);
}

.box-shadow-min {
  -webkit-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  -moz-box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
}

/* widget style 1 */
.widget-style1 {
  padding: 20px 10px;
  background: var(--header_bgColor);
}

.widget-style1 .circle-icon {
  width: 60px;
}

.widget-style1 .circle-icon .icon {
  width: 60px;
  height: 60px;
  background: var(--bg_active_color);
  display: flex;
  align-items: center;
  justify-content: center;
}

.widget-style1 .widget-data {
  width: calc(100% - 150px);
  padding: 0 15px;
}

.profile__img i {
  font-size: 20px;
  color: var(--icon_color);
  position: relative;
  cursor: pointer;
  padding: 8px;
  transition: 1s;
}

.profile__img i:hover {
  background: var(--bg_active_color);
  border-radius: 4px;
}

.dot__bell {
  width: 8px;
  height: 8px;
  position: absolute;
  background: var(--notification_dot);
  border-radius: 50%;
  top: 7px;
  right: 7px;
  border: 2px solid var(--bg_white);
}

.collapsed__submenu {
  position: fixed;
  left: 50px;
  background: var(--bg_white);
  margin-top: -45px;
  border: 1px solid var(--border_color);
  border-radius: 0px 4px 4px 0px;
  border-left: 0;
}

.collapsed__submenu.billing {
  animation-name: openDropdownCollapsedSubadmin;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  overflow: hidden;
  text-wrap: nowrap;
  z-index: 9;
}

.collapsed__submenu.setting {
  animation-name: openDropdownCollapsedAccount;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  overflow: hidden;
  text-wrap: nowrap;
  z-index: 9;
}

.small__sidebar {
  padding: 7px 12px;
  margin: 4px 0;
  transition: 1s;
  cursor: pointer;
}

.small__sidebar:hover {
  background: var(--bg_active_color);
  color: var(--header_logo_txt_color);
}

.collapsed__submenu.box-shadow-max i {
  font-size: 13px;
}

.collapsed__submenu.box-shadow-max span {
  font-size: 13px;
}

.small__sidebar_activeSubmenu {
  background: var(--bg_active_color);
  color: var(--header_logo_txt_color);
}

.align_arrow {
  font-size: 10px !important;
}

/* ------------ Header Css ---------------- */

.navbar_right {
  display: flex;
}

.notify_icon img,
.icon_wrap img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid var(--border_color);
}

.react-resizable {
  position: relative;
  white-space: nowrap;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
}

.icon_wrap {
  cursor: pointer;
}

.icon_wrap .title_name {
  transition: 1s;
  font-weight: 600;
  font-size: 12px;
}

.icon_wrap .title_name:hover {
  background: var(--bg_active_color);
  border-radius: 4px;
  color: var(--header_logo_txt_color);
  font-weight: 900;
}

.notifications {
  margin-right: 15px;
}

.profile,
.notifications {
  position: relative;
}

.profile .profile_dd,
.notification_dd {
  position: absolute;
  top: 43px;
  right: 0px;
  user-select: none;
  background: var(--bg_white);
  border-top: 0;
  width: 350px;
  box-shadow: 10px 10px 35px rgba(0, 0, 0, 0.125),
    -10px -10px 35px rgba(0, 0, 0, 0.125);
  z-index: 9;
}

/* .profile .profile_dd:before,
.notification_dd:before {
  content: "";
  position: absolute;
  top: -14px;
  right: 6px;
  border: 8px solid;
  border-color: var(--transparent_color) var(--transparent_color)
    var(--bg_white) var(--transparent_color);
} */

.notification_ul,
.profile_ul {
  padding: 0;
  overflow: hidden;
  margin: 0;
  max-height: 0;
  transition: max-height 0.5s ease-in-out;
}

.notification_dd li {
  border-bottom: 1px solid var(--border_color);
  padding: 5px 15px;
  display: flex;
  align-items: center;
  transition: 1s;
}

.notification_dd li:hover {
  background: var(--bg_active_color);
  cursor: pointer;
}

.notification_dd li.show_all:hover {
  background: var(--transparent_color);
  cursor: pointer;
}

.notification_dd li .notify_icon {
  display: flex;
}

.notification_dd li .notify_data {
  margin: 0 15px;
  width: 255px;
}

.notification_dd li .notify_data .title {
  color: var(--header_logo_txt_color);
  font-weight: 600;
  font-size: 12px;
}

.notification_dd li .notify_data .sub_title {
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notification_dd li .notify_status p {
  font-size: 12px;
}

.notification_dd li.show_all {
  padding: 10px;
  display: flex;
  justify-content: center;
}

.notification_dd li.show_all p {
  font-weight: 700;
  font-size: 12px;
  color: var(--icon_color);
  cursor: pointer;
  margin: 0;
}

.notification_dd li.show_all a {
  text-decoration: none;
}

.notification_dd li.show_all p:hover {
  text-decoration: underline;
}

.profile .icon_wrap {
  display: flex;
  align-items: center;
  gap: 5px;
}

.profile .profile_dd {
  width: 150px;
}

.profile .profile_dd ul li {
  border-bottom: 1px solid var(--border_color);
  list-style: none;
}

.profile .profile_dd ul li a {
  display: block;
  padding: 10px 15px;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  transition: 1s;
  color: var(--inactive_menu_color);
}

.profile .profile_dd ul li button {
  display: block;
  padding: 10px 15px;
  position: relative;
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  transition: 1s;
  background: var(--bg_white);
  color: var(--inactive_menu_color);
}

.profile_ul li:last-child .logout-link:hover {
  background-color: var(--alert_background);
}

.profile .profile_dd ul li a .picon {
  display: inline-block;
  width: 30px;
  transition: 1s;
}

.profile .profile_dd ul li button .picon {
  display: inline-block;
  width: 27px;
  transition: 1s;
  margin-left: 3px;
}

.profile .profile_dd ul li a:hover {
  color: var(--header_logo_txt_color);
  background: var(--bg_active_color);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.profile.active .profile_ul,
.notifications.active .notification_ul {
  max-height: 300px;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.show_nav_title {
  display: none;
}

.active_profile_menu_dropdown {
  color: var(--header_logo_txt_color);
  background: var(--bg_active_color);
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.profileNameActive {
  background: var(--bg_active_color);
  border-radius: 4px;
  color: var(--header_logo_txt_color);
  font-weight: 900;
}

.close_icon i {
  color: var(--notification_dot);
  display: none;
  font-size: 14px;
}

.show_nav_title i {
  font-size: 18px;
}

.side-menu li.logout:hover {
  background: var(--alert_background);
  cursor: pointer;
  border-left: 4px solid var(--notification_dot) !important;
}

/* ---------- Landing Page Css --------------- */

a,
button {
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
  background: none;
  box-shadow: none;
  text-decoration: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.section {
  margin: 0 auto;
  padding: 6rem 0 1rem;
}

.heading-xl {
  font-family: inherit;
  font-size: clamp(2.648rem, 6vw, 4.241rem);
  font-weight: 700;
  line-height: 1.15;
  letter-spacing: -1px;
}

.btn-darken {
  padding: 0.75rem 2rem;
  width: 40%;
  color: var(--header_logo_txt_color);
  background-color: var(--bg_active_color);
}

.btn-darken:hover {
  background-color: var(--icon_color);
  color: var(--bg_white);
  font-weight: 700;
}

.btn-neutral {
  padding: 0.75rem 2rem;
  color: var(--inactive_menu_color);
  background-color: var(--icon_color);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: auto;
  margin: 0 auto;
  transition: all 0.35s ease;
}

.header.on-scroll {
  background: var(--header_bgColor);
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1.25rem;
  width: 100%;
  height: 4.25rem;
  margin: 0 auto;
}

.brand {
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.5;
  letter-spacing: 1px;
  color: var(--icon_color);
  text-transform: uppercase;
}

.menu {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: auto;
  padding: 4rem 0 3rem;
  overflow: hidden;
  transition: all 0.4s ease-in-out;
}

.menu.is-active {
  top: 0;
  width: 100%;
  height: auto;
  background: var(--icon_color);
}

.menu-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.25rem;
}

.menu-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--bg_active_color);
  text-transform: uppercase;
  transition: all 0.3s ease;
}

.menu-link:hover {
  color: var(--icon_color);
  font-weight: 700;
}

.menu-block {
  display: inline-block;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  margin-left: auto;
  padding: 0.65rem 1.5rem;
  border-radius: 3rem;
  text-transform: capitalize;
  color: var(--header_logo_txt_color);
  background-color: var(--bg_active_color);
  transition: 1s;
}

.menu-block:hover {
  background-color: var(--icon_color);
  color: var(--bg_white);
  font-weight: 700;
}

.burger {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
  order: -1;
  z-index: 10;
  width: 1.6rem;
  height: 1.15rem;
  border: none;
  outline: none;
  background: none;
  visibility: visible;
  transform: rotate(0deg);
  transition: 0.35s ease;
}

.burger-line {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 1rem;
  transform: rotate(0deg);
  background-color: var(--icon_color);
  transition: 0.25s ease-in-out;
}

.burger-line:nth-child(1) {
  top: 0px;
}

.burger-line:nth-child(2) {
  top: 0.5rem;
  width: 70%;
}

.burger-line:nth-child(3) {
  top: 1rem;
}

.burger.is-active .burger-line:nth-child(1) {
  top: 0.5rem;
  transform: rotate(135deg);
}

.burger.is-active .burger-line:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}

.burger.is-active .burger-line:nth-child(3) {
  top: 0.5rem;
  transform: rotate(-135deg);
}

.banner-column {
  position: relative;
  display: grid;
  align-items: center;
  row-gap: 3rem;
}

.banner-image {
  display: block;
  max-width: 18rem;
  height: auto;
  margin-top: 2rem;
  object-fit: cover;
  justify-self: center;
}

.banner-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.75rem;
}

.banner-links {
  position: absolute;
  top: 30%;
  right: 1.5rem;
  display: grid;
  justify-items: center;
  row-gap: 0.5rem;
  opacity: 0;
  visibility: hidden;
}

.banner-links i:hover {
  transition: 1s;
  color: var(--icon_color);
}

.banner-links > * {
  font-size: 1.25rem;
  line-height: 1.25;
  color: var(--bg_active_color);
}

.banner-links::before {
  position: absolute;
  content: "";
  top: -3rem;
  width: 4rem;
  height: 1.5px;
  transform: rotate(90deg);
  background: var(--bg_active_color);
}

.banner-links::after {
  position: absolute;
  content: "";
  bottom: -3rem;
  width: 4rem;
  height: 2px;
  transform: rotate(90deg);
  background: var(--bg_active_color);
}

/* ------------------------- Responsive CSS Here ---------------- */

@media (max-width: 767px) {
  .sidebar {
    position: fixed;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px,
      rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
    z-index: 3;
    animation-name: sideBarColaps;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    overflow: hidden;
    background: var(--bg_white);
  }

  .sidebar.collapsed {
    display: none;
  }

  .close_icon i {
    display: block;
  }

  .notification_dd {
    display: none !important;
  }

  .pagination {
    display: block !important;
  }

  .sponsors_section_filter input {
    width: 125px;
  }

  .paginate ul {
    margin-top: 10px !important;
  }

  .paginate ul li a {
    padding: 0px 6px !important;
  }

  .sign-up__form {
    padding: 15px !important;
  }

  .Form_justification {
    display: block !important;
  }

  .invite__form {
    padding: 10px !important;
  }

  .graph__title p {
    text-align: center;
  }
}

@media (max-width: 579px) {
  .header__collaps {
    display: none;
  }

  .title_name {
    display: none !important;
  }

  .show_nav_title {
    display: block;
  }

  #menu-btn {
    width: 35px;
  }
}

@media (hover: none) {
  .scrollbox::-webkit-scrollbar {
    width: 3px;
  }

  .scrollbox::-webkit-scrollbar-thumb {
    background-color: var(--icon_color);
  }
}

@media only screen and (min-width: 48rem) {
  .menu {
    position: relative;
    top: 0;
    width: auto;
    height: auto;
    padding: 0rem;
    margin-left: auto;
    background: none;
    box-shadow: none;
  }

  .menu-inner {
    display: flex;
    flex-direction: row;
    column-gap: 2rem;
    margin: 0 auto;
  }

  .menu-link {
    text-transform: capitalize;
  }

  .menu-block {
    margin-left: 2rem;
  }

  .burger {
    display: none;
    visibility: hidden;
  }

  .banner-column {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: center;
  }

  .banner-image {
    order: 1;
    max-width: 20rem;
    height: auto;
  }
}

@media only screen and (min-width: 64rem) {
  .banner-column {
    grid-template-columns: 1fr max-content;
    column-gap: 4rem;
    margin-top: 3rem;
  }

  .banner-image {
    max-width: 25rem;
    height: auto;
    margin-right: 5rem;
  }

  .banner-links {
    opacity: 1;
    visibility: visible;
  }
}

/* Sponsors CSS ------------------------ */

.sponsors_section_filter input {
  border-radius: 3px 0px 0px 3px;
  border-right: 0 !important;
  height: 30px;
  font-size: 12px;
  border: 1px solid var(--icon_color);
}

.sponsors_section_filter button {
  width: 30px;
  height: 30px;
  padding: 0px;
  border-radius: 3px;
  background: var(--transparent_color);
  border: 1px solid var(--icon_color);
  transition: 1s;
  padding: 0px 8px;
  white-space: nowrap;
}

.close_circle {
  background: var(--transparent_color) !important;
  border: 1px solid var(--red_color) !important;
  color: var(--red_color);
  transition: 1s;
}

.close_circle i {
  color: var(--red_color) !important;
}

.close_circle:hover {
  background: var(--red_color) !important;
  border: 1px solid var(--red_color) !important;
  color: var(--bg_white) !important;
}

.close_circle:hover i {
  color: var(--white_color) !important;
}

.sponsors_section_filter button:hover,
.buttonActive {
  border: 1px solid var(--icon_color);
  background: var(--icon_color);
}

.sponsors_section_filter button i {
  font-size: 12px;
  color: var(--icon_color);
  background: var(--transparent_color);
}

.sponsors_section_filter button:hover i {
  color: var(--bg_white);
}

.add_sponsor_field label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--form_label_color);
}

.add_sponsor_field label span {
  color: var(--notification_dot);
}

.add_sponsor_field input,
.add_sponsor_field .form-select {
  transition: 0.5s;
  /* height: 35px; */
  /* padding: 7px; */
  font-size: 12px;
  border-radius: 3px;
}

.add_sponsor_field .form-control {
  transition: 0.5s;
  font-size: 12px;
  border-radius: 3px;
}

.form-control:disabled:hover {
  border: 1px solid var(--border_color);
}

.add_sponsor_field input:hover,
.add_sponsor_field .form-select:hover,
.add_sponsor_field textarea:hover {
  border: 1px solid var(--icon_color);
}

.footer_button button {
  width: 100px;
  border-radius: 3px;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  background: var(--icon_color);
  border: 1px solid var(--icon_color);
  transition: 1s;
}

.footer_button .btn-info {
  --bs-btn-color: var(--bg_white);
}

.footer_button button:hover {
  color: var(--icon_color);
  border: 1px solid var(--icon_color);
  background: var(--transparent_color);
}

.btn:focus-visible {
  color: var(--white_color);
  background-color: var(--transparent_color);
  border-color: var(--icon_color);
  outline: 0;
  box-shadow: none;
}

.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  background-color: var(--icon_color);
  border-color: var(--icon_color);
}

/* Modal Css ------------------------------------- */

.modal-title.h4 {
  font-size: 15px;
  font-weight: 600;
  color: var(--bg_white);
}

.modal-header {
  padding: 10px 20px;
  background: var(--icon_color);
}

.offcanvas-header {
  padding: 10px 20px;
  background: var(--icon_color);
}

.modal-header .close_modal {
  transition: 1s;
  color: var(--notification_dot);
  background: var(--bg_white);
  border-radius: 50%;
  font-size: 11px;
  border: 1px solid var(--notification_dot);
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bolder;
  cursor: pointer;
}

.modal-header .close_modal:hover {
  transform: rotate(180deg);
}

.action__buttons i {
  color: var(--icon_color);
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.action__buttons button:hover i {
  color: var(--bg_white);
}

.saperate_action_button {
  background: var(--bg_white);
  padding: 4px 8px;
  display: flex;
  gap: 5px;
  border-radius: 20pc;
  position: absolute;
  right: 50px;
  top: 2px;
  box-shadow: rgba(0, 0, 0, 0.24) -10px 13px 60px 12px;
}

.action__buttons button {
  height: 25px;
  width: 25px;
  padding: 8px;
  background: var(--transparent_color);
  border: 1px solid var(--icon_color);
  transition: 1s;
}

.action__buttons button:hover {
  border: 1px solid var(--icon_color);
  background: var(--icon_color);
}

.designation_select,
.permission_select,
.status_select {
  text-align: -webkit-center !important;
}

.status_select .form-select,
.designation_select .form-select,
.permission_select .form-select {
  transition: 0.5s;
  height: 25px;
  font-size: 11px;
  border-radius: 25px;
  width: 100px;
}

.designation_select .active_status,
.permission_select .active_status {
  font-weight: 600;
  border-color: var(--icon_color);
  color: var(--icon_color);
  width: 140px;
  padding: 0px 10px;
}

/* .status_select {
  display: flex;
  justify-content: center;
} */

.status_select .active_status {
  border-color: var(--active_color);
  color: var(--active_color);
  font-weight: 600;
  padding: 0 10px;
}

.status_select .inactive_status {
  border-color: var(--inactive_color);
  color: var(--inactive_color);
  font-weight: 600;
  padding: 0 10px;
}

.red_color_status {
  color: var(--inactive_color) !important;
  font-weight: 600;
}

.green_color_status {
  color: var(--active_color) !important;
  font-weight: 600;
}

.gray_color_status {
  color: var(--pending_color) !important;
  font-weight: 600;
}

.status_select .pending_status {
  border-color: var(--pending_color);
  color: var(--pending_color);
  font-weight: 600;
  padding: 0 10px;
}

.MainHeadTitle {
  font-size: 20px;
  display: flex;
  justify-content: center;
  font-weight: 600;
  margin: 0 0 10px 0;
}

.moduleName {
  font-weight: 600;
  margin-bottom: 15px;
}

.moduleGroup label {
  font-size: 13px;
}

.moduleGroup {
  margin-left: 15px;
}

.moduleGroup .form-check-input[type="checkbox"] {
  border-radius: 3px !important;
  border: 1px solid var(--icon_color) !important;
  cursor: pointer;
  padding: 0;
}

.moduleGroup .form-check-input:checked {
  background-color: var(--icon_color);
  color: var(--bg_white);
}

.moduleGroup .form-check-input:focus {
  outline: 0;
  box-shadow: none !important;
}

.assign_permission_button button {
  color: var(--bg_white);
  width: 140px;
  font-size: 14px;
  padding: 4px;
  border-radius: 3px;
}

.assign_permission_button {
  background: var(--header_bgColor);
  text-align: end;
  padding: 5px;
  position: fixed;
  width: -webkit-fill-available;
  bottom: 0;
}

.permissionList_height {
  overflow-x: hidden;
  height: calc(100vh - 150px);
}

/* Pagination Css ------------------ */

.pagination {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  margin-top: 10px;
}

.paginationResultCount {
  font-weight: bold;
  font-size: 12px;
}

.paginate {
  font-size: 12px;
  overflow: auto;
}

.paginate ul {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  gap: 5px;
}

.paginate ul a {
  margin: 0 0.25rem;
  font-weight: 500;
}

.paginate ul li.selected a {
  font-weight: bold;
}

.paginate ul li.selected {
  background: var(--header_bgColor);
  font-weight: bold;
}

.paginate ul li a {
  padding: 7.2px 10px;
  color: var(--bg_white);
}

.paginate ul li {
  border: 1px solid var(--icon_color);
  padding: 5.3px 0px;
  border-radius: 3px;
}

.paginate ul li:first-child {
  background-color: var(--icon_color);
  font-weight: bold;
  color: var(--bg_white);
}

.paginate ul li:last-child {
  background-color: var(--icon_color);
  font-weight: bold;
  color: var(--bg_white);
  margin-right: 0;
}

.paginate li {
  font-size: 12px;
  list-style: none;
}

.login_text {
  color: var(--active_color) !important;
  font-weight: 600;
  border: 1px solid var(--active_color);
  border-radius: 20px;
  padding: 1px 15px;
  width: 80px;
}

.logout_text {
  color: var(--inactive_color) !important;
  font-weight: 600;
  border: 1px solid var(--inactive_color);
  border-radius: 20px;
  padding: 1px 15px;
  width: 80px;
}

.apexcharts-datalabel,
.apexcharts-pie-label {
  display: none !important;
}

.invite__form {
  max-width: 850px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  border-radius: 10px;
}

.Form_justification {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.invite__form p {
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-size: 20px;
}

.invite__form_message p {
  display: flex;
  justify-content: center;
  font-weight: bold;
  padding-bottom: 10px;
  text-transform: uppercase;
  font-size: 20px;
}

.invite__form_message {
  max-width: 550px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
}

.Success__taag {
  text-align: center;
  display: flex;
  justify-content: center;
}

.Success__taag i {
  font-size: 60px;
}

/* Profile Avator -------------------------------*/

.avatar-upload {
  position: relative;
  max-width: 190px;
  margin: 0px auto;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 40px;
  z-index: 1;
  top: 10px;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: var(--bg_white);
  border: 1px solid var(--transparent_color);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:hover {
  background: var(--bg_opacity);
  border-color: var(--border_color);
}

.avatar-upload .avatar-edit input + label:after {
  content: "\f040";
  font-family: "FontAwesome";
  color: var(--bg_opacity);
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 155px;
  position: relative;
  border-radius: 100%;
  border: 6px solid var(--border_color);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.1);
}

.avatar-upload .avatar-preview img {
  border-radius: 50%;
  height: 144px;
  object-fit: cover;
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.leaveOptionBox {
  background-color: var(--header_bgColor);
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.08);
  border-top: 2px solid var(--icon_color);
  border-radius: 3px;
  padding: 5px 12px;
  white-space: nowrap;
}

.optionButton {
  color: var(--black_color);
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0;
}

.optionActiveButton {
  color: var(--black_color);
  padding: 6px 10px;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0;
  background-color: var(--bg_active_color);
  border-left: 4px solid var(--icon_color) !important;
  border-radius: 6px 0 0 6px;
}

.optionButton:hover {
  transition: 0.2s;
  background-color: var(--bg_active_color);
  border-left: 4px solid var(--icon_color) !important;
  border-radius: 6px 0 0 6px;
  cursor: pointer;
}

.sponsors_section_filter button:hover .statusButton,
.activeStatusButton,
span.statusButton {
  color: var(--bg_white);
  font-weight: 500;
  font-size: 13px;
}

span.statusButton,
.txt_color {
  color: var(--icon_color);
}

.activeStatusButton {
  background: var(--icon_color) !important;
}

.activeStatusButton .spanTextSize {
  color: var(--bg_white);
}

span.spanTextSize {
  font-size: 13px;
}

.input-group-appends {
  position: absolute;
  right: -7px;
  top: -6px;
  background: #ee7c7c;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 15px;
  width: 15px;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
  z-index: 9;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 7px 12px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: var(--icon_color);
  border: var(--icon_color);
  border-radius: 3px;
}

.input-group-text.changeLogs {
  font-size: 13px;
  width: 80px;
}

.buttonBaseColor {
  cursor: pointer;
  color: var(--icon_color);
}

/* ---------------- Key Frame Css -------------------- */

@keyframes openDropdownSubadmin {
  from {
    height: 5px;
  }

  to {
    height: 113px;
  }
}

@keyframes openDropdownaiAlphaControl {
  from {
    height: 5px;
  }

  to {
    height: 230px;
  }
}

@keyframes openDropdownCollapsedSubadmin {
  0% {
    width: 0px;
  }

  100% {
    width: 132px;
  }
}

@keyframes openDropdownEditor {
  from {
    height: 5px;
  }

  to {
    height: 265px;
  }
}

@keyframes openDropdownAccount {
  from {
    height: 5px;
  }

  to {
    height: 152px;
  }
}

@keyframes openDropdownCollapsedAccount {
  0% {
    width: 0px;
  }

  100% {
    width: 135px;
  }
}

@keyframes openSidebarSection {
  0% {
    width: 0px;
  }

  100% {
    width: 250px;
  }
}

.qr__imag {
  display: flex;
  justify-content: center;
}

.qr__imag img {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
  width: 225px;
}

.qr__imag .security-shield {
  padding: 20px 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
  text-align: center;
}

.qr__imag .security-shield i {
  font-size: 150px;
}

.noRecordFound {
  text-align: center;
  font-size: 20px;
  padding: 20px 0;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: var(--header_bgColor);
  border-color: var(--icon_color);
  color: var(--icon_color);
  cursor: no-drop;
}

.form-control:focus {
  border-color: var(--icon_color);
}

.form-select:focus {
  border-color: var(--icon_color);
}

.imglogin {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  background: #000;
  height: 100vh;
}

.imglogin img {
  width: 450px;
  height: 450px;
}

.librarySection ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.collapsed {
  transition: 1s;
}

.search input {
  padding: 4px 10px;
  font-size: 11px;
  border-radius: 3px;
}

.uploadButton button {
  padding: 5px 10px 4px;
  font-size: 10px;
}

.transactionId,
.paymentId {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.transactionId {
  width: 350px;
}

.paymentId {
  width: 200px;
}

@media print {
  .printButton {
    display: none;
  }
}

.rerceiptTable tbody tr:nth-child(odd) td {
  background-color: #fff;
}

.printFloatingButton {
  position: fixed;
  bottom: 20px;
  right: 50%;
}

.card-body {
  background: var(--header_bgColor);
}

.activeHelpMenu {
  background-color: var(--bg_active_color);
  color: var(--header_logo_txt_color);
  border-left: 4px solid var(--icon_color) !important;
  border-radius: 6px 0 0 6px;
}

.inActiveHelpMenu {
  background-color: var(--bs-border-color);
  color: var(--header_logo_txt_color);
  border-left: 4px solid var(--bg_opacity) !important;
  border-radius: 6px 0 0 6px;
}

/* Context Menu  */

.react-contextmenu-wrapper {
  font-size: small;
  font-weight: initial;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

nav.react-contextmenu {
  min-width: 240px;
  border-radius: 4px;
  background-color: var(--bs-gray-100);
  padding: 10px 0;
  z-index: 99;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
}

.react-contextmenu-item:hover:not(.react-contextmenu-item--disabled) {
  background-color: var(--bg_active_color);
  border-left: 4px solid var(--icon_color);
  border-radius: 6px 0px 0 6px;
}

.react-contextmenu .react-contextmenu-item {
  font-size: 13px;
  text-decoration: none;
  cursor: pointer;
  transition: 0.2s;
  position: relative;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  outline: none;
  color: var(--form_label_color);
  padding: 10px 15px;
  font-weight: 600;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-dark-border-subtle) !important;
}

.react-contextmenu-item .edit {
  color: var(--active_color);
}

.react-contextmenu-item .lock {
  color: var(--pending_color);
}

.react-contextmenu-item .delete {
  color: var(--red_color);
}

.react-contextmenu-item .history {
  color: rgb(80, 193, 80);
}

.titleName {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  vertical-align: middle;
}

.libraryHeight ul {
  max-height: calc(100vh - 330px);
  overflow: auto;
}

.rightHeightColumn {
  height: calc(100vh - 105px);
}

.tablePropertiesHeight {
  max-height: calc(100vh - 230px);
  overflow: auto;
}

.form-check-input {
  width: 13px;
  height: 13px;
  padding: 5px;
}

.inputforSecelct .input-group-text {
  padding: 0 10px;
}

.promptText {
  max-height: calc(100vh - 140px);
  overflow: auto;
  font-size: 13px;
}

.promptText code {
  white-space: pre-line !important;
}

.badgesChips {
  display: flex;
  flex-wrap: wrap;
}

.badgesChips .chip {
  border: 1px solid var(--icon_color);
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 3px;
  margin-right: 5px;
}

.badgesChips .chip span {
  font-size: 10px;
  padding: 3px 5px;
  background: var(--icon_color);
  color: var(--bg_white);
}

.badgesChips .chip i {
  font-size: 10px;
  cursor: pointer;
  padding: 0 5px;
  color: var(--icon_color);
}

.TablePre pre {
  margin: 0;
}

.TablePre td {
  background-color: ghostwhite !important;
  position: relative;
}

.TablePre td i {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.OutputToolbar-Left i {
  cursor: pointer;
  color: var(--icon_color);
}

.OutputToolbar-Left button:hover {
  background-color: transparent !important;
}

.OutputToolbar-Left .dropdown-toggle {
  background: transparent !important;
  border: 0;
}

.OutputToolbar-Left .dropdown-toggle::after {
  display: none;
}


.DropDownAdd button{
  background: transparent !important;
  border-color: transparent !important;
}

.DropDownAdd .dropdown-menu {
  padding: 0;
  overflow: hidden;
  border-radius: 3px;
}

.DropDownAdd .dropdown-menu .dropdown-item{
  font-size: 13px;
}

.DropDownAdd button::after{
  display: none;
}

.dropdown-item:active{
  background-color: var(--icon_color) !important;
}

.log_change {
  background-color: whitesmoke;
  background-size: 100%;
  border: 1px solid #d4d4d4;
  display: block;
  line-height: 19px;
  margin-bottom: 10px;
  overflow: visible;
  overflow-y: hidden;
  padding: 0 0 0 4px;
  font-size: 14px;
}

.log_change pre{
  white-space: normal;
}