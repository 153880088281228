img {
  width: 100%;
}

button {
  cursor: pointer;
  background: none;
}

/* --------- components --------- */
.section__Module {
  display: flex;
  min-height: 100vh;
}
.section__Module section {
  width: 100%;
  flex: 1 1;
}

.hero {
  position: relative;
  width: 60%;
}

.hero .overlay-black {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #132b2747;
}
.hero .logo {
  position: absolute;
  top: 40px;
  left: 40px;
  width: 72px;
  border-radius: 50%;
  z-index: 3;
}
.hero-img {
  object-fit: cover;
  height: 100vh;
}

.hero-super {
  position: absolute;
  bottom: 88px;
  left: 40px;
  color: var(--bg_white);
  font-size: 44px;
  font-weight: 600;
  line-height: normal;
  z-index: 3;
  transition-delay: 0.2s;
}

.sign-up {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 40px;
}

.sign-up h1 {
  font-size: 22px;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 32px;
  text-align: center;
}

.sign-up__form {
  width: 100%;
  max-width: 500px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 40px;
  border-radius: 10px;
}

.sign-up__form-control {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}

/* .sign-up__form button {
  width: 100%;
  display: inline-block;
  margin-top: 8px;
  padding: 7px;
  border-radius: 3px;
  border: 1px solid var(--bg_active_color);
  background: var(--bg_active_color);
  text-align: center;
  font-size: 14px;
  transition: all 0.3s ease-in-out;
  font-weight: 600;
  letter-spacing: 1px;
}
.sign-up__form button:hover {
  background-color: var(--icon_color);
  border: 1px solid var(--icon_color);
  color: var(--bg_white);
} */
.sign-up h3 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 416px;
  color: var(--bg_opacity);
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 24px;
}
.sign-up h3::after,
.sign-up h3::before {
  content: " ";
  display: inline-block;
  width: 30%;
  height: 1px;
  background-color: var(--bg_opacity);
}
.sign-up__social {
  width: 100%;
  max-width: 416px;
  display: flex;
  gap: 20px;
}
.sign-up__social-button {
  padding: 12px 16px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 3px;
  border: 1px solid var(--bg_active_color);
  background: var(--bg_active_color);
  transition: border-color 0.3s ease-in-out;
}

.eye_password i {
  position: absolute;
  right: 10px;
  top: 21px;
  font-size: 10px;
  cursor: pointer;
}

.sign-up__social-button img {
  width: 24px;
}

p.message a {
  font-size: 14px;
  color: var(--icon_color);
  text-decoration: none;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  letter-spacing: 0.5px;
}

/* -------------------- Otp Verification Css ----------------------- */

.title p {
  font-size: 12px;
}

.title p.msg {
  color: initial;
  text-align: initial;
  font-weight: bold;
  margin: 0;
}

.otp-input-fields {
  width: auto;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 20px;
}

.otp-input-fields input {
  height: 35px;
  width: 35px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid var(--icon_color);
  text-align: center;
  outline: none;
  font-size: 16px;
}

.otp-input-fields input::-webkit-outer-spin-button,
.otp-input-fields input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.otp-input-fields input:focus {
  border-width: 2px;
  border-color: var(--icon_color);
  font-size: 20px;
}

.sign-up__form-control i {
  position: absolute;
  right: 7px;
  top: 23px;
  transform: translateY(-50%);
  cursor: pointer;
}

/*------------ Responsive Here --------------*/
@media screen and (max-width: 768px) {
  .section__Module {
    flex-direction: column;
  }
  .hero-img {
    height: 350px;
  }
  .hero {
    width: 100%;
  }
  .hero-super {
    font-size: 32px;
  }
  .sign-up {
    padding: 32px 24px;
  }
}

@media screen and (max-width: 992px) {
  .hero-super {
    font-size: 36px;
  }
}
